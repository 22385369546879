export const environment = {
      baseURL: 'http://carsdailytestapi.antimtechnologies.com/api/v1',
      imageURL: 'http://carsdailytestapi.antimtechnologies.com',
      websiteURL: 'http://carsdailytest.antimtechnologies.com',
      // baseURL: 'http://localhost:3002/api/v1',
      // imageURL: 'http://localhost:3002',
      // baseURL: 'http://192.168.1.30:3002/api/v1',
      // imageURL: 'http://192.168.1.30:3002',
      production: false,
      firebaseConfig: {
            apiKey: "AIzaSyAmn2cA9m8AX3dJ5mXrpWxsM6Vm3R_EDXA",
            authDomain: "cars-daily-13326.firebaseapp.com",
            projectId: "cars-daily-13326",
            storageBucket: "cars-daily-13326.appspot.com",
            messagingSenderId: "129773453776",
            appId: "1:129773453776:web:d201ccb7586ef1ce8979d5",
            measurementId: "G-YY16TCT4E7",
            vapidKey: "BNFp3xKrdCrBfnyl4MrDJxN5lXoJO74EAbsDCjSg6sFE8YagiEuTxYIcMz3AnjpAYkxN7t_a4h3sBOJdhZthEuo"
      }
};
