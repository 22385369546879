import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from '../../../enums';

@Component({
  selector: 'app-introduction',
  standalone: false,
  templateUrl: './introduction.component.html',
  styleUrl: './introduction.component.scss'
})
export class IntroductionComponent {

  @ViewChild('swiperContainer', { static: false }) swiperContainer!: ElementRef;

  constructor(
    private router: Router
  ) { }


  // Function to go to the next slide programmatically
  goToNextSlide() {
    const swiper = this.swiperContainer.nativeElement.swiper;
    if (swiper) {
      swiper.slideNext();  // Call Swiper's slideNext() method
    }
  }

  getStarted() {
    this.router.navigate(["/" + Paths.main]);
  }
}
