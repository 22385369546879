import { Component, HostBinding, OnInit } from '@angular/core';
import { trigger, transition, animate, style, query, stagger, group } from '@angular/animations';
import { Router } from '@angular/router';
import { LocalStorage, Paths } from '../../../enums';
import { CheckNullUndefinedService, LocalStorageService } from '../../../services';

@Component({
  selector: 'app-splash-screen',
  standalone: false,
  templateUrl: './splash-screen.component.html',
  animations: [
    trigger('pageAnimations', [
      transition(':enter', [
        group([
          query('.hero, form', [
            style({ opacity: 0, transform: 'translateX(-100px)' }),
            stagger(-30, [
              animate('1000ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))
            ])
          ]),
          query('.splash-text, form', [
            style({ opacity: 0, transform: 'translateY(100px)' }),
            stagger(-0, [
              animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))
            ])
          ])
        ])
      ])
    ])
  ]
})
export class SplashScreenComponent implements OnInit {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private checkNullUndefinedService: CheckNullUndefinedService
  ) {
    
  }

  @HostBinding('@pageAnimations')
  ngOnInit(): void {
    setTimeout(() => {
      this.pageRedirect();
    }, 3000);
  }

  pageRedirect() {
    const introduction = this.localStorageService.getData(LocalStorage.introduction);

    if (!this.checkNullUndefinedService.checkNullAndUndefined(introduction) || introduction !== "true") {
      this.localStorageService.setData(LocalStorage.introduction, "true");
      this.router.navigate(["/" + Paths.introduction]);
    }
    else {
      this.router.navigate(["/" + Paths.main]);
    }
  }
}
