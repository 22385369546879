<div class="splashScreen">
    <div class="logo">
        <img src="../../../../assets/images/app_logo.png" alt="logo" />
    </div>
    <div class="image-contain">
        <img class="hero" src="../../../../assets/images/splash-car.png" alt="car" />
    </div>
    <div class="splash-text">
        <p>Premium and prestige car needs proper care<br> Experience the thrill and satisfaction to drive.</p>
        <!-- <a (click)="pageRedirect()" class="common-btn">Let's Go</a> -->
    </div>
</div>