

export class CouponOutputData implements ICouponOutputData {
    /** The wish list id of wish list master */
    cart_id!: number;
    /** The user id of user master */
    user_id!: number;
    /** The auto generated from tbl_product */
    product_id!: number;
    /** The auto generated from tbl_service */
    service_id!: number;
    /** The auto generated from tbl_category */
    category_id!: number;
    /** The coupon code name with 500 character limit */
    coupon_code_name!: string;
    /** The coupon code with 500 character limit */
    coupon_code!: string;
    /** The coupon code type should be AMOUNT or PERCENTAGE */
    coupon_code_type!: string;
    /** The discount on decided coupon */
    discount!: number;
    /** The maximum discount on amount */
    maximum_discount_amount!: number;
    /** The minimum amount for discount */
    minimum_amount_for_discount!: number;
    /** The flag for identify is user new or old value should be 0 or 1 */
    is_for_new_user_or_amount_based!: number;
    /** The type will be All, NEW, AMOUNT */
    type!: string;
    /** The coupon code ID */
    coupon_code_id!: number;
    /** Discount coupon description */
    coupon_code_description!: string;
    /** Item name based on category or product */
    title_name!: string;

    constructor(data?: ICouponOutputData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.cart_id = _data["cart_id"];
            this.user_id = _data["user_id"];
            this.product_id = _data["product_id"];
            this.service_id = _data["service_id"];
            this.category_id = _data["category_id"];
            this.coupon_code_name = _data["coupon_code_name"];
            this.coupon_code = _data["coupon_code"];
            this.coupon_code_type = _data["coupon_code_type"];
            this.discount = _data["discount"];
            this.maximum_discount_amount = _data["maximum_discount_amount"];
            this.minimum_amount_for_discount = _data["minimum_amount_for_discount"];
            this.is_for_new_user_or_amount_based = _data["is_for_new_user_or_amount_based"];
            this.type = _data["type"];
            this.coupon_code_id = _data["coupon_code_id"];
            this.coupon_code_description = _data["coupon_code_description"];
            this.title_name = _data["title_name"];
        }
    }

    static fromJS(data: any): CouponOutputData {
        data = typeof data === 'object' ? data : {};
        let result = new CouponOutputData();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["cart_id"] = this.cart_id;
        data["user_id"] = this.user_id;
        data["product_id"] = this.product_id;
        data["service_id"] = this.service_id;
        data["category_id"] = this.category_id;
        data["coupon_code_name"] = this.coupon_code_name;
        data["coupon_code"] = this.coupon_code;
        data["coupon_code_type"] = this.coupon_code_type;
        data["discount"] = this.discount;
        data["maximum_discount_amount"] = this.maximum_discount_amount;
        data["minimum_amount_for_discount"] = this.minimum_amount_for_discount;
        data["is_for_new_user_or_amount_based"] = this.is_for_new_user_or_amount_based;
        data["type"] = this.type;
        data["coupon_code_id"] = this.coupon_code_id;
        data["coupon_code_description"] = this.coupon_code_description;
        data["title_name"] = this.title_name;
        return data; 
    }
}

export interface ICouponOutputData {
    /** The wish list id of wish list master */
    cart_id: number;
    /** The user id of user master */
    user_id: number;
    /** The auto generated from tbl_product */
    product_id: number;
    /** The auto generated from tbl_service */
    service_id: number;
    /** The auto generated from tbl_category */
    category_id: number;
    /** The coupon code name with 500 character limit */
    coupon_code_name: string;
    /** The coupon code with 500 character limit */
    coupon_code: string;
    /** The coupon code type should be AMOUNT or PERCENTAGE */
    coupon_code_type: string;
    /** The discount on decided coupon */
    discount: number;
    /** The maximum discount on amount */
    maximum_discount_amount: number;
    /** The minimum amount for discount */
    minimum_amount_for_discount: number;
    /** The flag for identify is user new or old value should be 0 or 1 */
    is_for_new_user_or_amount_based: number;
    /** The type will be All, NEW, AMOUNT */
    type: string;
    /** The coupon code ID */
    coupon_code_id: number;
    /** Discount coupon description */
    coupon_code_description: string;
    /** Item name based on category or product */
    title_name: string;
}

