import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { SelectCouponOutput } from '../models/select-coupon-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class CouponServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3002/api/v1";
    }

    /**
     * @return Coupon found successfully
     */
    getCouponList(): Observable<SelectCouponOutput> {
        let url_ = this.baseUrl + "/Coupon/GetCouponList";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processGetCouponList(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processGetCouponList(<any>response_);
                } catch (e) {
                    return <Observable<SelectCouponOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectCouponOutput>><any>_observableThrow(response_);
        }));
    }

    protected processGetCouponList(response: HttpResponseBase): Observable<SelectCouponOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectCouponOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectCouponOutput>(<any>null);
    }
}
