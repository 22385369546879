<div class="container-fluid bg-white">
    <button class="skipbtn" (click)="getStarted()">Skip <i class="fa-solid fa-forward"></i></button>
    <div class="row align-items-center introScreen">
        <div class="col-sm-12 col-md-12 col-lg-6 mx-auto">
            <h5 class="text-center">Your go-to hub for everything car-related!</h5>
            <!-- pagination="true"  -->
            <swiper-container slides-per-view="1" loop="true" autoplay="true" autoplay-delay="3000" #swiperContainer>
                <swiper-slide class="text-center pb-5 h-100">
                    <img src="../../../../assets/images/intro-1.png" alt="Banner Image" class="slide pb-5">
                    <div class="introText pt-5">
                        <h1>Book Car Care Services</h1>
                        <p>Get ready to rev up your ride! Easily schedule maintenance and repairs with our trusted
                            pros—your car deserves the best!</p>
                        <button class="nextPage swiper-no-swiping" (click)="goToNextSlide()"><i class="fa-solid fa-angle-left"></i></button>
                    </div>
                </swiper-slide>
                <swiper-slide class="text-center pb-5 h-100">
                    <img src="../../../../assets/images/intro-2.png" alt="Banner Image" class="slide pb-5">
                    <div class="introText pt-5">
                        <h1>Shop Car Accessories</h1>
                        <p>Elevate your driving game! Dive into a fantastic selection of top-notch accessories that will
                            transform your car into a true reflection of your style!</p>
                        <button class="nextPage swiper-no-swiping" (click)="goToNextSlide()"><i class="fa-solid fa-angle-left"></i></button>
                    </div>
                </swiper-slide>
                <swiper-slide class="text-center pb-5 h-100">
                    <img src="../../../../assets/images/intro-3.png" alt="Banner Image" class="slide pb-5">
                    <div class="introText pt-5">
                        <h1>Explore Used Cars</h1>
                        <p>Your dream car is just a click away! Browse our extensive listings and get expert advice to
                            find the perfect ride that fits your lifestyle!</p>
                        <button class="apply-btn swiper-no-swiping" (click)="getStarted()">Get Started</button>
                    </div>
                </swiper-slide>
            </swiper-container>
        </div>
    </div>
</div>